import * as deepmerge from 'deepmerge';

import {ENVIRONMENT_DEV} from './global/dev';
import {ENVIRONMENT_US} from './local/us';

export const environment = deepmerge.all<any>([
  {},
  ENVIRONMENT_DEV,
  ENVIRONMENT_US,
  {
    angularProduction: true,
    endpoint: 'https://web.us.stage.tulotero.net/tuloteroweb/rest',
    enableAuthDomains: ['us.stage.tulotero.net'],
    endpointCommon: {
      ALL: 'https://static.us.stage.tulotero.net/allinfo.json',
      es_ES: 'https://static.us.stage.tulotero.net/allinfo_es_ES.json',
      en_US: 'https://static.us.stage.tulotero.net/allinfo_en_US.json',
      es_MX: 'https://static.us.stage.tulotero.net/allinfo_es_MX.json',
    },
    endpointTicketImage: 'https://web.us.stage.tulotero.net',
    serviceWorkerEnabled: true,
    features: {
      subscribe: {
        enable: true,
        maxDaysSelectorBreakpoint: 'lg',
      },
      money: {
        providers: {
          fiserv: {
            scriptUrl:
              'https://cat.api.firstdata.com/gateway/v2/connectpay/static/v1/js/PaymentSDK.js',
          },
        },
      },
    },
    appleRedirectUri: 'https://web.us.stage.tulotero.net',
    noDeleteAllInfoInVersionURL:
      'https://static.stage.tulotero.net/versions-web-pre.json',
  },
]);
