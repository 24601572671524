import {INTERNAL_ROUTES} from '../routes/internal-routes';

export const ENVIRONMENT_GLOBAL = {
  appVersion: '1283',
  device: 'WEB',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  defaultHeaders: {AvoidRedirect: 'true', Client: 'web'},
  scroll: {scrollPaginatePercentage: 80},
  allInfo: {
    intervalToUpdateInfo: 200,
    boothCacheDuration: 3600000,
    trustCacheDuration: 14400000,
    reduce: {minTickets: 12, maxTickets: 50, maxMoneyActivies: 50},
  },
  auth: {
    persist: true,
  },
  history: {
    limit: 15,
  },
  fingerprint: {token: 'etOEJuRBz3quPn3J2wOm'},
  locale: {
    user: {
      allowDocumentNumber: false,
      documentExpirationDatePattern: 'dd/MM/yyyy',
    },
  },
  selfExclusion: {
    showInfo: false,
    showDailyLimit: false,
  },
  selfControl: {
    maxLimit: 99999,
  },
  money: {
    tpvRedirect: document.location.origin + '/tpv.html',
    trustlyRedirect: document.location.origin + '/trustlyResult.html',
    limitYearSummary: 2020,
    creditCardAliasLength: 20,
    bizum: {
      enable: false,
    },
    amountLoad: {
      showDecimals: true,
      allowInputManual: true,
    },
    resizeVoucherImageMaxDimension: 1200,
    transfer: {
      enableSpei: false,
    },
  },
  social: {
    facebookRedirect: document.location.origin + '/fb.html',
    whatsappLink: {
      mobile: 'https://api.whatsapp.com/send?phone={phone}&text={message}',
      desktop: 'https://web.whatsapp.com/send?phone={phone}&text={message}',
    },
    useNativeSms: false,
  },
  games: {
    logos: {
      rounded: false,
    },
    lottery: {
      ticketsEndpoint: 'tuloteroweb/decimo',
      allowCustomSearch: false,
      // show the available tickets in the lottery manual page
      showManualLotteryResultInfo: false,
      hideFilterButtons: false,
      showLotteryManualAmount: false,
      mobileWheelSmallFit: false,
      showBooths: false,
      allowSelectBoothId: [],
      ticketsSearchSizeClass: 'medium',
      // Indicates whether the ticket is owned by the user,
      // in which case it will be protected by BasicAuth.
      autheticatedImageTickets: false,
    },
    play: {
      requiredKyc: false,
    },
  },
  geolocation: {
    maxAccuracy: 100, // Set the max accuracy in metters
    highAccuracy: false,
    timeout: 15000, // value in ms.
    enableAppState: false,
    reduceLocationAddPayment: false,
  },
  google: {
    clientId:
      '314470230639-1a0j1p52voji0mho40o59ufl6r0i21j8.apps.googleusercontent.com',
    maps: {
      apikey: 'AIzaSyD6u3GuezpxboCQioH1eMhikPbMbcd6FXY',
      queryLink: 'https://www.google.com/maps/search/?api=1&query=',
      defaultZoomIn: 9,
    },
  },
  apple: {
    login: true,
    clientId: 'com.tulotero.webapp.sid',
  },
  promos: {
    // time to live in minutes
    ttl: 30,
  },
  alerts: {readSpeedFactor: 2},
  preferences: {
    defaultPushOptions: ['notif_compra', 'notif_premio', 'notif_superbotes'],
  },
  browserSupport: [
    {name: 'Chrome', version: 45},
    {name: 'Firefox', version: 53},
    {name: 'Safari', version: 9},
    {name: 'Mobile Safari', version: 10},
    {name: 'Edge', version: 15},
    {name: 'Opera', version: 40},
  ],
  maintenance: {
    redirectUrl: (localeCode: string) => `maintenance-${localeCode}.html`,
  },
  animations: {christmas: false, birthday: false, bigPrize: true},
  temporalPrize: {limit: 2000},
  prize: {
    min: 150,
    max: 5000,
  },
  markets: {
    ios: {
      id: 948041023,
    },
  },
  // indicate whether the backend server is in UTC format
  serverOnUTC: false,
  showMessageHooks: true,
  kyc: {
    resizeImageHeight: 640,
    compressImage: 0.8,
    showProfileDNI: true,
    showLegalTermsSMS: false,
    methods: {
      // Set default kyc method based on identity document type
      defaultBasedOnIdentityDocument: false,
    },
    enableNIF: false,
    // show link to verification passport using barcode
    showKycStepPassport: false,
  },
  serviceWorkerEnabled: false,
  useIndexedDB: false,
  enableLocalStorageMigration: false,
  app: {
    apkVerticalImage: false,
    android: {
      downloadUsingPlayStore: true,
    },
  },
  localStorage: {
    token: 'local-storage',
  },
  menu: {
    showClock: false,
    backgroundHalloween: 'assets/img/mobile/menu/background-halloween.jpg',
  },
  profile: {
    backgroundHalloween: 'assets/img/mobile/menu/background-halloween.jpg',
  },
  showDesktopFooter: false,
  verification: {
    maxTimeBlockedUser: 43200, // Time in seconds
  },
  features: {
    multiLanguage: true,
    groups: {
      filterGames: false,
    },
    verificationDialogNewFlow: false,
    money: {
      annualSummary: false,
      uploadVoucher: false,
    },
    profile: {
      showState: true,
    },
    // Subscribe specific days and by jackpot
    subscribe: {
      enable: false,
      maxDaysSelectorBreakpoint: 'lg',
    },
    signUp: {
      // Show promo code input directly in sign up screen
      showPromoInput: false,
    },
    keepPrizeInGroups: false,
    reserveMode: false,
    tickets: {
      filterGames: false,
      cancelBet: false,
      showTicketInfo: false,
      downloadProof: false,
      showProof: false,
    },
    booths: {
      enabled: false,
      distanceAlert: {near: 49000, far: 300000},
      showRetailers: false,
      remoteBooths: false,
    },
    showHideClubs: false,
    trust: {
      showOfficialRetailer: false,
      showWinners: false,
      welcomeGift: false,
      ratings: false,
    },
    games: {
      lottery: {
        // Enable button change Booth on Delivery
        allowChangeRandomBoothOnDelivery: false,
        // Enable redirect to lottery number
        redirectToLotteryNumber: false,
      },
    },
    prizes: {
      enableSignature: false,
    },
  },
  christmasSeason: {
    start: 1, // DD of December
    end: 7, // DD of January
  },
  halloweenSeason: {
    start: 25, // DD of October
    end: 3, // DD of November
  },
  useGoogleSDK: true,
  groups: {
    onlyOneAdmin: false,
    userBoxPrice: true,
  },
  internalUrls: INTERNAL_ROUTES,
  christmasBanner: {
    showBanner: false,
  },
};
